
import Clientpage from './components/clientpage/Clientpage';
import { Router,Routes, Route, Link } from "react-router-dom";
function App() {
  return (
    <Clientpage />
  );
}

export default App;
